<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
    @keydown.esc="$emit('close')"
  >
    <v-card
      class="elevation-0 ma-auto"
      tile
      width="1400"
      :max-width="$vuetify.breakpoint.mobile ? '95%' : '70%'"
    >
      <div class="pl-16 ml-5 pr-10">
        <OnboardingProgressBar @change-step="changeStep" />
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            absolute
            left
            color="white"
            elevation="0"
            class="mt-3"
            v-bind="attrs"
            v-on="on"
            @click="cancel"
          >
            <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
          </v-btn>
        </template>
        <span class="text-capitalize">{{ $t("action.close") }}</span>
      </v-tooltip>

      <v-window v-model="onboardingStep" touchless style="width: 100%">
        <v-window-item :value="1">
          <StartedOnboarding @started="nextStep" />
        </v-window-item>

        <v-window-item :value="2">
          <CreateUser
            v-if="!created"
            @back="previousStep"
            @user-created="handleCreated()"
            @user-selected="userSelected()"
          />
          <UserInstructions
            v-else
            @back-to-create="backToCreate"
            @next="nextStep"
          />
        </v-window-item>

        <v-window-item :value="3">
          <UpdateUserSignatureInfo
            @back="handleBackAfterUser"
            @next="nextStep"
          />
        </v-window-item>

        <v-window-item :value="4">
          <UpdateUser @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="5">
          <UpdateUserApplySignature @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="6">
          <AddShift @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="7">
          <EnterpriseResources @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="8">
          <ConcludedOnboarding @exit="goHome" @go-to-user="goToUser" />
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import AddShift from "@/components/onboardingNewUser/AddShift.vue";
import ConcludedOnboarding from "@/components/onboardingNewUser/ConcludedOnboarding.vue";
import CreateUser from "@/components/onboardingNewUser/CreateUser.vue";
import EnterpriseResources from "@/components/onboardingNewUser/EnterpriseResources.vue";
import OnboardingProgressBar from "@/components/onboardingNewUser/OnboardingProgressBar.vue";
import StartedOnboarding from "@/components/onboardingNewUser/StartedOnboarding.vue";
import UpdateUser from "@/components/onboardingNewUser/UpdateUser.vue";
import UpdateUserApplySignature from "@/components/onboardingNewUser/UpdateUserApplySignature.vue";
import UpdateUserSignatureInfo from "@/components/onboardingNewUser/UpdateUserSignatureInfo.vue";
import UserInstructions from "@/components/onboardingNewUser/UserInstructions.vue";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "OnboardingNewUser",
  components: {
    OnboardingProgressBar,
    StartedOnboarding,
    CreateUser,
    UpdateUserSignatureInfo,
    UpdateUserApplySignature,
    UpdateUser,
    AddShift,
    ConcludedOnboarding,
    EnterpriseResources,
    UserInstructions,
  },
  data() {
    return {
      show: true,
      next: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentOnboardingStep",
      "mainDomain",
      "onboardingUser",
      "onboardingCreatedUser",
      "onboardingForms",
    ]),

    onboardingStep: {
      get() {
        return this.currentOnboardingStep;
      },
      set(newStep) {
        this.setOnboardingStep(newStep);
      },
    },

    stepsNames() {
      return {
        1: "welcome",
        2: "create_user",
        3: "signature_informations",
        4: "user_informations",
        5: "apply_signature",
        6: "shifts_control",
        7: "enterprise_resources",
        8: "conclusion",
      };
    },

    created() {
      return !!this.onboardingCreatedUser.email;
    },
  },

  methods: {
    ...mapMutations([
      "setOnboardingStep",
      "setOnboardingUser",
      "setOnboardingCreatedUser",
      "clearOnboardingForms",
    ]),
    ...mapActions(["checkUserById"]),

    saveAnalytics({ event, click }) {
      setFirebaseEvent(this.mainDomain, event, click);
    },

    async handleCreated() {
      await this.checkUserById();
    },

    cancel() {
      this.$router.go(-1);
      const log = {
        event: `cancel_onboarding_on_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click:
          "Botão de fechar no canto superior esquerdo do fluxo de onboarding",
      };
      this.saveAnalytics(log);
    },
    previousStep() {
      const log = {
        event: `returned_onboarding_to_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click:
          "Botão de voltar no canto superior esquerdo do fluxo de onboarding",
      };
      this.saveAnalytics(log);

      this.onboardingStep = this.onboardingStep - 1;
      if (this.onboardingStep === 1 && this.onboardingUser.id_google) {
        this.setOnboardingUser();
      }
    },
    backToCreate() {
      this.removeStorageData({ clearOnboardingUser: this.created });
      const log = {
        event: "returned_to_create_user_onboarding",
        click: "Botão de voltar para criação de usuário",
      };
      this.saveAnalytics(log);
    },
    nextStep() {
      const log = {
        event: `next_onboarding_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click: "Botão de avançar para o próximo step no fluxo de onboarding",
      };
      this.saveAnalytics(log);
      this.onboardingStep = this.currentOnboardingStep + 1;
    },
    changeStep(ongoingStep) {
      if (
        (this.currentOnboardingStep >= 3 && ongoingStep < 2) ||
        (this.currentOnboardingStep === 2 && ongoingStep === 2)
      ) {
        this.removeStorageData({ clearOnboardingUser: this.created });
      }
      const log = {
        event: `next_onboarding_step_${this.stepsNames[ongoingStep]}`,
        click: "Botão de selecionar step no fluxo de onboarding",
      };
      this.saveAnalytics(log);
      this.onboardingStep = ongoingStep;
    },
    removeStorageData({ clearOnboardingUser = true } = {}) {
      if (clearOnboardingUser) this.setOnboardingUser();
      this.setOnboardingCreatedUser();
      this.clearOnboardingForms();
      localStorage.removeItem("userGroups");
    },

    resetAll() {
      this.removeStorageData();
      this.setOnboardingStep();
    },

    async goToUser() {
      await this.$router.push({
        name: "User",
        query: { id: this.onboardingUser.id_google },
      });
      this.resetAll();
    },

    async goHome() {
      await this.$router.push({ name: "Home" });
      const log = {
        event: "finish_onboarding",
        click:
          "Botão de voltar para home no último step do fluxo de onboarding",
      };
      this.saveAnalytics(log);
      this.resetAll();
    },

    async userSelected() {
      if (this.onboardingUser.id_google) {
        if (this.currentOnboardingStep == 2) this.nextStep();
        await this.checkUserById();
      }
    },

    handleBackAfterUser() {
      if (!this.created) this.backToCreate();
      this.previousStep();
    },
  },

  beforeMount() {
    if (this.onboardingUser.id_google && !this.onboardingUser.key) {
      this.checkUserById().finally();
    }
  },
};
</script>

<style></style>
