<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      max-width="80vw"
      height="calc(89vh - 100px)"
      class="mt-6 elevation-0 ma-auto overflow-auto"
      flat
      tile
    >
      <v-list-item>
        <v-list-item-action class="mr-4">
          <v-icon v-text="'mdi-check-circle'" :color="'green'" />
        </v-list-item-action>

        <v-list-item-title class="text-wrap break-word normal-line-height">
          {{ $t("onboardingNewUser.instructions.userCreated") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="m3-5 yellow lighten-5 py-2 mt-3">
        <v-list-item-action class="mr-4">
          <v-icon v-text="'mdi-lightbulb-on-10'" :color="'accent'" />
        </v-list-item-action>

        <v-list-item-title class="text-wrap break-word normal-line-height">
          {{ $t("onboardingNewUser.instructions.reminderInfos") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-action class="mr-4">
          <v-icon v-text="'mdi-alert'" :color="'warning'" />
        </v-list-item-action>

        <v-list-item-title class="text-wrap break-word normal-line-height">
          <span
            v-html="$t('onboardingNewUser.instructions.recommendations')"
          ></span>
        </v-list-item-title>
      </v-list-item>
      <v-divider class="mt-5"></v-divider>
      <v-row
        class="d-flex align-center mt-6"
        align="center"
        justify="center"
        align-content="center"
        v-for="(option, i) in copyOptions"
        :key="i"
      >
        <v-col cols="12" class="mb-3 ml-6" v-if="i === 0">
          <h3>
            {{ $t("onboardingNewUser.instructions.copyLoginInstructions") }}
          </h3>
        </v-col>
        <v-card
          class="grid text-center pa-4 mb-3"
          outlined
          width="80%"
          @click="copy(option.element)"
        >
          <v-card-actions class="align-center">
            <v-spacer />

            <span
              class="text-h6 text-sm-h6 text-md-64 font-weight-regular secondary--text text--lighten-2"
              :id="option.element"
            >
              {{ option.value }}
            </span>

            <v-spacer />

            <v-icon v-text="'mdi-content-copy'" />
          </v-card-actions>
        </v-card>
      </v-row>
      <v-divider class="mt-8"></v-divider>
      <v-row
        class="d-flex align-center mt-6 ml-1"
        align="center"
        justify="center"
        align-content="center"
      >
        <v-col cols="12">
          <h3>
            {{ $t("onboardingNewUser.instructions.sendLoginInstructions") }}
          </h3>
        </v-col>
        <v-col cols="12">
          <span>{{
            $t("onboardingNewUser.instructions.sendInfoDescription")
          }}</span>
        </v-col>
        <v-col cols="12">
          <v-btn
            height="52"
            color="primary"
            class="mx-auto text-none text-body-1 font-weight-bold px-8 mb-5"
            @click="showEmailInstructions = true"
          >
            {{ $t("action.viewAndSend") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl"
      width="90vw"
    >
      <v-btn
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        @click="$emit('back-to-create')"
        text
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>
      <v-spacer />
      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8"
        @click="$emit('next')"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>

    <!-- MODAL DE INSTRUÇÕES DE LOGIN -->
    <HeaderDialog
      ref="instructions"
      id="user_instructions"
      :title="$t('onboardingNewUser.instructions.sendLoginInstructions')"
      :show="showEmailInstructions"
      width="700"
      :persistent="false"
      @close="showEmailInstructions = false"
      @action="sendEmail"
      :disabledAction="!email || !validateEmail"
      :close-text="$t('action.cancel')"
      :action-text="$t('common.scheduleSend')"
      :loading="loading"
    >
      <template v-slot:body>
        <v-container fluid class="d-flex flex-column fill-height pb-0 mb-0">
          <v-row style="width: 90%" class="d-flex flex-column">
            <v-text-field
              v-model="email"
              :label="$t('common.recipientEmail')"
              class="mt-4 mb-4"
              :rules="[validEmail]"
            />
            <v-row>
              <v-col
                :class="`${$vuetify.breakpoint.xs ? 'px-0' : 'pr-0'}`"
                lg="7"
                md="7"
                sm="7"
                xs="7"
                cols="12"
              >
                <v-menu
                  v-model="menuScheduleDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      append-icon="mdi-calendar"
                      :value="scheduleDate"
                      :label="$t('common.choiceDateToSendEmail')"
                      :hint="$t('common.limitDate')"
                      :placeholder="$t('common.dateToschedule')"
                      readonly
                      hide-details
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="scheduleDateSelected"
                    :min="addDaysFormat(0)"
                    :max="addDaysFormat(29)"
                    @input="menuScheduleDate = false"
                  >
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                :class="`${$vuetify.breakpoint.xs ? 'px-0' : 'pr-0'}`"
                lg="4"
                md="4"
                sm="4"
                xs="4"
                cols="12"
              >
                <v-text-field
                  v-model="hour"
                  :label="$t('common.hour')"
                  type="time"
                  hide-details
                  outlined
                />
              </v-col>
            </v-row>
            <v-checkbox
              style="width: 100%"
              v-model="sendCopy"
              :label="$t('common.sendCopyToMe')"
              class="mb-0 py-0"
            ></v-checkbox>
            <v-card tile class="mt-4 mb-4" max-width="600">
              <div>
                <v-divider />

                <v-card-subtitle class="py-1">
                  <strong>{{ $t("common.subject") }}:</strong>
                  <span class="px-2">{{ subject }}</span>
                </v-card-subtitle>
                <v-divider />
              </div>
              <v-card flat class="px-6 pt-4 pb-2 overflow-y-auto transparent">
                <span v-html="emailHtml"></span>
              </v-card>
            </v-card>
          </v-row>
        </v-container>
      </template>
    </HeaderDialog>
  </v-container>
</template>

<script>
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import {
  addDaysFormat,
  createDatetimeWithDateAndHour,
  formatDate,
  todayDataBaseFormat,
} from "@/helpers/services/utils";
import { snackbarMessages } from "@/helpers/variables/snackbarMessages";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserInstructions",
  props: {},
  data() {
    return {
      showEmailInstructions: false,
      email: "",
      sendCopy: false,
      loading: false,
      menuScheduleDate: false,
      scheduleDateSelected: todayDataBaseFormat,
      hour: "23:59",
      validEmail: (e) =>
        !!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(e) ||
        this.$t("common.notValidEmail"),
    };
  },
  computed: {
    ...mapGetters(["company", "currentUser", "onboardingCreatedUser"]),
    copyOptions() {
      return [
        { element: "email", value: this.onboardingCreatedUser.email },
        { element: "password", value: this.onboardingCreatedUser.password },
      ];
    },
    emailHtml() {
      return this.$t("onboardingNewUser.instructions.templateEmail.content", {
        company_name: this.company.name,
        user_email: this.onboardingCreatedUser.email,
        user_password: this.onboardingCreatedUser.password,
      });
    },
    subject() {
      return this.$t(
        "onboardingNewUser.instructions.templateEmail.subjectEmail",
        {
          company_name: this.company.name,
        }
      );
    },

    scheduleDate() {
      if (!this.scheduleDateSelected) {
        return null;
      }
      return formatDate(this.scheduleDateSelected);
    },

    validateEmail() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return !!emailRegex.test(this.email);
    },
  },
  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["sendIntructionsEmail"]),

    addDaysFormat,

    copy(element) {
      var elm = document.getElementById(element);

      var selection = window.getSelection();
      var range2 = document.createRange();
      range2.selectNodeContents(elm);
      selection.removeAllRanges();
      selection.addRange(range2);
      document.execCommand("Copy");

      this.setSnackBar({
        message: snackbarMessages.copied_email,
        color: "success",
        show: true,
      });
    },
    sendEmail() {
      this.loading = true;
      const payload = {
        email: this.email,
        user_email: this.onboardingCreatedUser.email,
        password: this.onboardingCreatedUser.password,
        author_email: this.currentUser.email,
        company_name: this.company.name,
        send_copy: this.sendCopy,
        subject: this.subject,
        schedule_date: createDatetimeWithDateAndHour(
          this.scheduleDateSelected,
          this.hour
        ),
      };
      this.sendIntructionsEmail(payload)
        .then(() => {
          this.setSnackBar({
            show: true,
            message: snackbarMessages.email_sended,
            color: "success",
          });
        })
        .catch((error) => {
          console.error("sendIntructionsEmail: ", error.response);
          this.setSnackBar({
            show: true,
            message: snackbarMessages.EMAIL_NOT_SENDED,
            color: "error",
          });
        })
        .finally(() => {
          this.showEmailInstructions = false;
          this.loading = false;
          const log = {
            event: "onboarding_send_instructions_email",
            click:
              "Botão de enviar instruções de login para o novo usuário no fluxo de onboarding",
          };
          this.saveAnalytics(log);
        });
    },
    saveAnalytics({ event, click }) {
      if (this.company.mainDomain) {
        setFirebaseEvent(this.company.mainDomain, event, click);
      }
    },
  },
};
</script>

<style></style>
